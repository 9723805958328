import unityLogo from './../images/svgs/technology logos/unityLogo.svg';
import csharpLogo from '../images/svgs/technology logos/csharpLogo.svg';
import artifact_select from '../images/monsterclicker_gallery/mc_artifact_select.png'
import blocking_rock from '../images/monsterclicker_gallery/mc_blocking_rock.png'
import buy_knife from '../images/monsterclicker_gallery/mc_buy_knife.png'
import dragon from '../images/monsterclicker_gallery/mc_dragon.png'
import select_mode from '../images/monsterclicker_gallery/mc_select_mode.png'
import squid from '../images/monsterclicker_gallery/mc_squid.png'
export default{
    title: "Monster Clicker",
    subtitle: "A never-ending dungeon crawler game",
    type: "Mobile Game",
    description: "Monster Clicker is a Unity singleplayer game where the player tries to time their attacks in order to beat the dungeon mobs with different abilities. The game features multiple dungeon areas with different mobs and keeps increasing in difficulty. Upon defeating an area, the player gains a reward to keep in the run and the mobs increase in difficulty. It is a mobile game.",
    hasVideo: true,
    videoLink: "https://www.youtube.com/embed/2Jl4J1Z2gBk",
    features: [
        "Multiple areas",
        "Mob abilities",
        "Increasing difficulty",
        "Rewards system",
        "Mobile game",
    ],
    technology: [
        "Unity",
        "C#",
    ],
    technologyLogos:[
        unityLogo,
        csharpLogo,
    ],
    technologyDescription: [
        "A cross-platform game engine",
        "A modern, object-oriented programming language",
    ],
    galleryPhotos:[
        artifact_select,
        blocking_rock,
        buy_knife,
        dragon,
        select_mode,
        squid,
    ],
    challanges:[
        {
            challenge_title: "Increasing difficulty",
            challenge_desc: "Implementing a system that increases the difficulty of the game was a challenge",
            solution_title: "Difficulty scaling",
            solution_desc: "I implemented a difficulty scaling system that increases the difficulty of the game as the player progresses"
        },
        {
            challenge_title: "Multiple dungeon areas",
            challenge_desc: "Creating multiple unique dungeon areas was a challenge",
            solution_title: "Unity's level design tools",
            solution_desc: "I used Unity's level design tools to create multiple unique dungeon areas"
        }
    ],
    code:[
        {
            code_title: "Increasing difficulty",
            code_desc: "I implemented a system that increases the difficulty of the game as the player progresses",
            code_snippet: `public class DifficultyScaler : MonoBehaviour
            {
                public float difficultyFactor = 1.05f;
                public int level = 1;

                public void IncreaseDifficulty()
                {
                    level++;
                    difficultyFactor *= level;
                }
            }`,
            code_language: "csharp"
        },
        {
            code_title: "Multiple dungeon areas",
            code_desc: "I used Unity's level design tools to create multiple unique dungeon areas",
            code_snippet: `public class LevelManager : MonoBehaviour
            {
                public List<Level> levels;

                public void LoadLevel(int levelIndex)
                {
                    SceneManager.LoadScene(levels[levelIndex].sceneName);
                }
            }`,
            code_language: "csharp"
        }
    ]
}
import './SCSS/video.scss'
import RotatingSassyText from "../../components/TextS/RotatingSassyText";
import EmblaCarousel from "../../components/GalleryCarousel/EmblaCarousel";
export default function Video({embedLink="https://www.youtube.com/embed/XUg-etwBync"}){
    return (
        <div
            id={"section_video"}
            className={'video'}>
            <RotatingSassyText text={"Video"} size={"60px"}/>

            <iframe
                className="responsive-iframe"
                src={embedLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
            </iframe>
        </div>
    )
}
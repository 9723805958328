import './SCSS/overview.scss'
import {useContext, useState} from "react";
import {ProjectContext} from "../../contexts/ProjectContext";
import './../../styles/typography.scss'
import './../../styles/misc.scss'
import './../../styles/buttons.scss'

import {ThemeContext} from "../../contexts/ThemeContext";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import RotatingSassyText from "../../components/TextS/RotatingSassyText";
import RotatingClassyText from "../../components/TextS/RotatingClassyText";
export default function Overview({}) {
    const {project} = useContext(ProjectContext)
    const {primaryColor,primaryColorLight,white} = useContext(ThemeContext)
    const redirectToWebsite = (website) => {
        window.open(website, '_blank');
    }
    return (
        <div id={'section_overview'} className="overview">
            <div className="overview__main">
                 <div className="overview__main__left">
                     <div className="overview__main__left__top">
                         <div className="overview__main__left__top__tech-stack">
                                {
                                    project.technologyLogos.map((tech, index) => {
                                        return (
                                            <img className={"overview__main__left__top__tech-stack--logo"} src={tech} alt=""/>
                                        )
                                    })
                                }
                         </div>
                         <RotatingSassyText text={project.title}/>
                         <span
                                style={{color: `${white}`} }
                             className="h8 overview__main__left__top--subtitle">{project.subtitle}</span>
                     </div>
                     <div className="overview__main__left__bottom">
                         <RotatingClassyText text={"description"} size={"50px"}/>
                         <p
                             style={{color: `${white}`}}
                             className="overview__main__left__bottom--text">{project.description}</p>

                         <div className="overview__main__left__bottom__features">
                             {
                                 project.features.map((feature, index) => {
                                     return (
                                         <span
                                             className="overview__main__left__bottom__features--feature">{project.features[index]}</span>
                                     )
                                 })
                             }
                         </div>
                         {/*technology*/}
                         <RotatingClassyText text={"tech stack"} size={"50px"}/>
                         <div className="overview__main__left__bottom__features">
                             {
                                 project.technology.map((feature, index) => {
                                     return (
                                         <span
                                             className="overview__main__left__bottom__features--feature">{project.technology[index]}</span>
                                     )
                                 })
                             }
                         </div>

                     </div>
                 </div>
                <div className="overview__main__right">
                    <div className="button-wrapper">
                        {project.liveLink && <PrimaryButton text={'live app'} onClick={() => {
                            redirectToWebsite(project.liveLink)
                        }}/>}
                        {project.githubLink && <PrimaryButton text={'github'} onClick={() => {
                            redirectToWebsite(project.githubLink)
                        }}/>}
                        {project.downloadLink && <PrimaryButton text={'download'} onClick={() => {
                            redirectToWebsite(project.downloadLink)
                        }}/>}
                    </div>
                    <div className="navigation-wrapper">
                        <RotatingClassyText text={"navigation"} size={"50px"}/>
                        <div className="overview__main__right__project-list">
                            <a

                                href={"#section_gallery"}
                                style={{color: `${white}`}}
                                className="overview__main__right__project-list--title clickable-text section-navigation-text">gallery</a>
                            {
                                project.hasVideo &&
                                <a href={"#section_video"}
                                    style={{color: `${white}`}}
                                    className="overview__main__right__project-list--title clickable-text section-navigation-text">video</a>
                            }
                            {/*<a*/}
                            {/*    href={"#section_tech_used"}*/}
                            {/*    style={{color: `${white}`}}*/}
                            {/*    className="overview__main__right__project-list--title clickable-text section-navigation-text">technology used</a>*/}
                            {/*<a*/}
                            {/*    href={"#section_challenges"}*/}
                            {/*    style={{color: `${white}`}}*/}
                            {/*    className="overview__main__right__project-list--title clickable-text section-navigation-text">challenges and Solutions</a>*/}
                            {/*<a*/}
                            {/*    href={"#section_code"}*/}
                            {/*    style={{color: `${white}`}}*/}
                            {/*    className="overview__main__right__project-list--title clickable-text section-navigation-text">code</a>*/}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
import './SCSS/rotating_rainbow_text.scss'
import {useState} from "react";
export default function RotatingRainbowText({text="Rainbow", size="80px", font="markpro, serif", lineHeight="normal", extraStyle={}}){

    return(
        <span
            className={"r-r-text"}

            style={{fontSize:size, fontFamily:font,lineHeight, ...extraStyle, position:"relative"}}>
            {text}

        </span>

    )
}
import React from 'react';

export const ThemeContext = React.createContext();

export default function ThemeContextProvider({children}) {
    const [theme, setTheme] = React.useState('default')

    //colors
    const [primaryColor, setPrimaryColor] = React.useState('#FFA629')
    const [primaryColorLight, setPrimaryColorLight] = React.useState('#FCD19C')
    const [secondaryColor, setSecondaryColor] = React.useState('white')
    const [accentColor, setAccentColor] = React.useState('black')

    const [white, setWhite] = React.useState('white')
    const [gray, setGray] = React.useState('#464242')

    const [green, setGreen] = React.useState('#51B773')

    const [backgroundColor, setBackgroundColor] = React.useState('black')//#1e1e1e
    const [foregroundColor, setForegroundColor] = React.useState('white')//#464242

    return (
        <ThemeContext.Provider value={{
            theme, setTheme,
            primaryColor, setPrimaryColor,
            secondaryColor, setSecondaryColor,
            accentColor, setAccentColor,
            backgroundColor, setBackgroundColor,
            foregroundColor, setForegroundColor,
            white, setWhite,
            primaryColorLight, setPrimaryColorLight,
            green, setGreen,
            gray, setGray

        }}>
            {children}
        </ThemeContext.Provider>
    )
}
import './App.scss';
import {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./containers/HomePage/HomePage";
import ProjectPage from "./containers/ProjectPage/ProjectPage";
import {NavBar} from "./components/NavBar";
import ThemeContextProvider from "./contexts/ThemeContext";
import ProjectContextProvider from "./contexts/ProjectContext";
//Hi from a diff repo
function App() {
  // const [bgColor, setBgColor] = useState('#1e1e1e')
  // const [foregroundColor, setForegroundColor] = useState('#ffffff')
  return (
      <ThemeContextProvider>
          <ProjectContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<><HomePage/></>}/>
                    <Route path="/project/:projectName" element={<><ProjectPage/></>}/>
                </Routes>
            </BrowserRouter>
          </ProjectContextProvider>
      </ThemeContextProvider>
  );
}

export default App;

import React, {useCallback, useContext} from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import Autoplay from 'embla-carousel-autoplay'
import imageByIndex from './imageByIndex'
import {ProjectContext} from "../../contexts/ProjectContext";

const EmblaCarousel = (props) => {
    const { slides, options } = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()])

    //context
    const {project} = useContext(ProjectContext)

    const onButtonClick = useCallback((emblaApi) => {
        const { autoplay } = emblaApi.plugins()
        if (!autoplay) return
        if (autoplay.options.stopOnInteraction !== false) autoplay.stop()
    }, [])

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
        emblaApi,
        onButtonClick
    )

    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((index) => (
                        <div className="embla__slide" key={index}>
                            <div className="embla__slide__number">
                                <span>{index + 1}</span>
                            </div>
                            <img
                                className="embla__slide__img"
                                src={imageByIndex(index, project.galleryPhotos)}
                                alt="Your alt text"
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/*<div className="embla__dots">*/}
            {/*    {scrollSnaps.map((_, index) => (*/}
            {/*        <DotButton*/}
            {/*            key={index}*/}
            {/*            onClick={() => onDotButtonClick(index)}*/}
            {/*            className={'embla__dot'.concat(*/}
            {/*                index === selectedIndex ? ' embla__dot--selected' : ''*/}
            {/*            )}*/}
            {/*        />*/}
            {/*    ))}*/}
            {/*</div>*/}
        </div>
    )
}

export default EmblaCarousel

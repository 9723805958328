import './SCSS/project_404.scss'
import {ThemeContext} from "../../contexts/ThemeContext";
import {useContext} from "react";
import RotatingSassyText from "../../components/TextS/RotatingSassyText";
import RotatingClassyText from "../../components/TextS/RotatingClassyText";
import {useNavigate} from "react-router-dom";

export const Project404 = ({props: Props}) => {
    const {backgroundColor, primaryColor,white} = useContext(ThemeContext)
    let navigate = useNavigate()
    return (
        <div
            style={{backgroundColor: `${backgroundColor}`}}
            className={"project-404"}>
            <RotatingSassyText text={"404"}/>
            <h2
                style={{color: `${white}`}}
                className="project-404__subtitle"
            >Page not found</h2>

            {/*asci art*/}
            <pre
                style={{color: `${white}`}}
                className="project-404__ascii"
            >
                {"     _...._\n" +
                    "   .-.     /\n" +
                    "  /o.o\\ ):.\\\n" +
                    "  \\   / `- .`--._\n" +
                    "  // /            `-.\n" +
                    " '...\\     .         `.\n" +
                    "  `--''.    '          `.\n" +
                    "      .'   .'            `-.\n" +
                    "   .-'    /`-.._            \\\n" +
                    " .'    _.'      :      .-'\"'/\n" +
                    "| _,--`       .'     .'    /\n" +
                    "\\ \\          /     .'     /\n" +
                    " \\///        |    ' |    /\n" +
                    "             \\   (  `.   ``-.\n" +
                    "              \\   \\   `._    \\\n" +
                    "            _.-`   )    .'    )\n" +
                    "  LGB       `.__.-'  .-' _-.-'\n" +
                    "                     `.__,'"}
            </pre>
            <div className="nav_wrapper" onClick={() => navigate('/')}>
                <RotatingClassyText text={"Click to go back to the home page"}/>
            </div>
        </div>
    );
};
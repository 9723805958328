import './SCSS/primary-button.scss'
import './../../styles/typography.scss'
import {ThemeContext} from "../../contexts/ThemeContext";
import {useContext} from "react";
export default function PrimaryButton({text, onClick=()=>{}}) {
    const {primaryColor} = useContext(ThemeContext)
    return (
        <div
            onClick={onClick}
            className="primary-button">
            {text}
        </div>
    )
}
import './SCSS/home_page.scss'
import {useContext, useState} from "react";
import {ThemeContext} from "../../contexts/ThemeContext";
import './../../styles/typography.scss'
import './../../styles/misc.scss'
import {useNavigate} from "react-router-dom";
import projects from '../../project-data/projects'
import {ProjectContext} from "../../contexts/ProjectContext";
import SmallerNavbar from "../../components/NavBars/SmallerNavbar";
import RotatingSassyText from "../../components/TextS/RotatingSassyText";
import RotatingClassyText from "../../components/TextS/RotatingClassyText";
import RotatingRainbowText from "../../components/TextS/RotatingRainbowText";
import ContactBox from "../../components/ContactBox";
export default function HomePage() {
    const [bgColor, setBgColor] = useState('black')//#1e1e1e
    const {primaryColor, secondaryColor, gray, white} = useContext(ThemeContext)
    const {selectedProject, selectProject} = useContext(ProjectContext)
    const navigate = useNavigate()
    const navigateToProject = (projectName) => {
        console.log(projectName)
        navigate(`/project/${projectName}`)
        selectProject(projectName)
    }
  return (
    <div
        style={{backgroundColor: bgColor}}
        className="home-page">
        <div className="s-nav-bar-wrapper">
            <SmallerNavbar/>
        </div>

        <div className="home-page__main-container">
          <div className="home-page__main-container__left">
              <p
                    style={{color: `${white}`}}
                  className="home-page__main-container__left--subtitle">
                  i love programming, and do so <RotatingSassyText text={"everyday"} size={"8rem"} extraStyle={{textAlign:"left"}}/>.
                  {/*<p style={{fontSize:"3rem", fontFamily: 'Roboto, serif'}}>i have experience in building solutions for <RotatingRainbowText  text={"diverse"} lineHeight={"40px"}/> applications. i`ve been self taught for 3 years.</p>*/}
              </p>
              {/*<ContactBox/>*/}

          </div>
          <div className="home-page__main-container__right">
                <RotatingSassyText text={"projects"}/>
              <div className="home-page__main-container__right__project-list">
                  {
                        Object.keys(projects).map((key, index) => {
                           let project = projects[key]
                            return (
                                <div className={"home-page__main-container__right__project-list__title"}>
                                    <div className="wrapper">
                                        <span
                                            key={index}
                                            onClick={() => navigateToProject(key)}
                                            style={{color: `${primaryColor}`}}
                                            className="home-page__main-container__right__project-list__title--name clickable-text"><RotatingClassyText text={project.title}/></span>
                                        <span
                                            style={{color: `${gray}`}}
                                            className="home-page__main-container__right__project-list__title--type h8">{project.type}</span>
                                        <div className="home-page__main-container__right__project-list__title__tech">
                                            {
                                                project.technologyLogos.map((logo, index) => {
                                                    return (
                                                        <>
                                                        <img
                                                            key={index}
                                                            src={`${logo}`}
                                                            className="home-page__main-container__right__project-list__title__tech--logo" alt={""}/>
                                                            <RotatingClassyText text={project.technology[index]} size={"2rem"}/>
                                                        </>
                                                    )
                                                })
                                            }


                                        </div>
                                    </div>
                                    <RotatingRainbowText text={"-------"} lineHeight={"3rem"} extraStyle={{justifySelf:"center"}}/>
                                </div>

                            )
                        })
                  }

              </div>
          </div>
      </div>
    </div>
  )
}
import './SCSS/s_nav_bar.scss'
import RotatingSassyText from "../TextS/RotatingSassyText";
import {DropDownBox} from "../DropDownBox";
import {useNavigate} from "react-router-dom";
import {ProjectContext} from "../../contexts/ProjectContext";
import {useContext} from "react";
export default function SmallerNavbar(){
    const navigate = useNavigate()
    const {selectedProject, selectProject} = useContext(ProjectContext)

    const handleNavigateToProject = (project) => {
        navigate(`/project/${project}`)
        selectProject(project)
    }
    return(
        <div className={"s-nav-bar"}>
            <div className="name-wrapper"
                 onClick={() => {
                        navigate('/')
                }}
            >
                <p style={{fontFamily:"markpro, serif",fontSize:"30px",lineHeight:"35px", color:"white"}}>i am</p>
                <RotatingSassyText text={"gustas rove"} size={"40px"} lineHeight={"43px"} extraStyle={{marginTop:"-14px"}}/>
            </div>

            <div className="project-wrapper">
                {/*<p style={{fontFamily: "markpro, serif", fontSize: "30px", lineHeight: "35px", color: "white"}}>select a project --></p>*/}
                <DropDownBox onSelect={handleNavigateToProject}/>
            </div>

        </div>
    )
}
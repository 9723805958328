import rustLogo from '../images/svgs/technology logos/rustLogo.png';
import dt_help from '../images/dirtorch_gallery/dt_help.png'
import dt_copy from '../images/dirtorch_gallery/dt_copy.png'
import dt_search from '../images/dirtorch_gallery/dt_search.png'
export default{
    title: "DirTorch",
    subtitle: "CLI file managment tool written in Rust that features multithreaded file searching and copying.",
    type: "CLI Tool",
    description: "DirTorch is a CLI file management tool written in Rust that features multithreaded file searching and copying. It features a plethora of other features such as compression, checksum, reading & writing to files and more.",
    githubLink: "https://github.com/TheMixas/DirTorch",
    features: [
        "multi-threaded",
        "directory management",
        "file searching",
        "file copying",
        "file compression",
    ],
    technology: [
        "Rust",
    ],
    technologyLogos:[
        rustLogo,
    ],
    technologyDescription: [
        "Rust is a systems programming language that runs blazingly fast, prevents segfaults, and guarantees thread safety."
    ],
    galleryPhotos:[
        dt_help,
        dt_copy,
        dt_search,
    ]
}
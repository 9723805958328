import './SCSS/drop_down_box.scss'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {ThemeContext} from "../contexts/ThemeContext";
import {ProjectContext} from "../contexts/ProjectContext";
import projects from '../project-data/projects'
import RotatingSassyText from "./TextS/RotatingSassyText";
import {useNavigate} from "react-router-dom";
export const DropDownBox = ({onSelect=()=>{}, selectedProject='Gossip'}) => {
    const {white, primaryColor, foregroundColor} = useContext(ThemeContext)
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    let navigate = useNavigate()
    const toggleDropdown = () => {
        console.log("toggle")
        setIsOpen(!isOpen);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div ref={dropdownRef}
            onClick={toggleDropdown}
            style={{backgroundColor: `${foregroundColor}`}}
            className={"drop_down_box"}>

            <span
                style={{color: `${white}`, fontFamily: "markpro,serif", fontSize: "3.5rem"}}
                className="drop_down_box__title">projects</span>
            {isOpen && (
                <div className="drop_down_box__options">
                    {Object.keys(projects).map((key, index) => {
                        let project = projects[key]
                        return <div
                            className={"drop_down_box__options__option"}
                            onClick={() => {
                                onSelect(key)
                            }}
                            key={index} value={key}>{project.title}</div>
                    })}
                </div>
            )}

        </div>
    );
};
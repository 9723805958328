import './SCSS/gallery.scss'
import '../../components/GalleryCarousel/embla.css'
import React, {useContext} from 'react';
import './../../styles/typography.scss'
import {ThemeContext} from "../../contexts/ThemeContext";
import EmblaCarousel from "../../components/GalleryCarousel/EmblaCarousel";
import {ProjectContext} from "../../contexts/ProjectContext";
import RotatingSassyText from "../../components/TextS/RotatingSassyText";
const OPTIONS = { loop: true }

export default function Gallery({}){
    const {primaryColor} = useContext(ThemeContext)
    const {project} = useContext(ProjectContext)
    //carousel
    const SLIDE_COUNT = project.galleryPhotos.length
    const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

    return (
        <div
            id={"section_gallery"}
            className={'gallery'}>
            <RotatingSassyText text={"Gallery"} size={"60px"}/>
            <EmblaCarousel slides={SLIDES} options={OPTIONS} />
        </div>
    );
};


import './SCSS/project_page.scss'
import Overview from "./Overview";
import {useContext} from "react";
import {ThemeContext} from "../../contexts/ThemeContext";
import Gallery from "./Gallery";
import TechUsed from "./TechUsed";
import {ChallengesSolutions} from "./ChallengesSolutions";
import {Code} from "./ Code";
import {BackToTop} from "../../components/BackToTop";
import {useParams} from "react-router-dom";
import projects from "../../project-data/projects";
import {Project404} from "./Project404";
import {ProjectContext} from "../../contexts/ProjectContext";
import SmallerNavbar from "../../components/NavBars/SmallerNavbar";
import Video from "./Video";
export default function ProjectPage({}) {
    const {backgroundColor} = useContext(ThemeContext)
    const {project,setProject} = useContext(ProjectContext)
    //get aprams
    const {projectName} = useParams()
    if(!projects[projectName]) return <Project404/>

    const handleLoadProjectData = () => {
        setProject(projects[projectName])
    }
    handleLoadProjectData()


  return (
    <div
        style={{backgroundColor: `${backgroundColor}`}}
        className="project-page">
        <div className="s-nav-bar-wrapper">
            <SmallerNavbar/>
        </div>
        <Overview/>
        <Gallery/>
        {
            project.hasVideo && <Video/>
        }
        {/*<BackToTop/>*/}
    </div>
  )
}